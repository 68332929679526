import React, { useCallback, useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function MainPage() {
    const [services, setServices] = useState([])  
    const [capelnisyas, setCapelnisyas] = useState([]) 
    const { t, i18n } = useTranslation();

    const fetchLinks = useCallback(() => {
    const serviceKeys = Object.keys(t('services', { returnObjects: true }));
    
    // Трансформация данных услуг
    const translatedServices = serviceKeys.slice(0, 3).map(key => ({
        _id: key,
        serviceName: t(`services.${key}.serviceName`),
        description: t(`services.${key}.description-main`),
        serviceImg: t(`services.${key}.serviceImg`)
    }));

    setServices(translatedServices);

    const capelnisyaKeys = Object.keys(t('capelnisyas', { returnObjects: true }));

     // Трансформация данных услуг
     const translatedCapelnisyas = capelnisyaKeys.slice(0, 3).map(key => ({
        _id: key,
        capelnisyaName: t(`capelnisyas.${key}.capelnisyaName`),
        description: t(`capelnisyas.${key}.description-main`),
        capelnisyaImg: t(`capelnisyas.${key}.capelnisyaImg`)
    }));

    setCapelnisyas(translatedCapelnisyas);
}, [t]);
    
    console.log("Capelnisyas: " , capelnisyas);

    useEffect(() => {
        fetchLinks();
    }, [fetchLinks, i18n.language]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
  return (
    <div>
        <Navbar/>
        <main className='main'>
            <header className='main-header'>
              <div className='clinic-description'>
                  <h1 className='header-title'>Nutrilife Clinic</h1>
                  <p>{t('main-page.header-content')}</p>
                  <div className='header-btns'>
                      <Link to="/reserve-clinic" className='header-btn'>{t('navbar.reserve')}</Link>
                      <Link to="/capelnisya" onClick={() => localStorage.setItem('lastNumTurn', 1)} className='header-btn'>
                            {t('btns.show-capelnisyas')} &gt;
                      </Link>
                  </div>
              </div>
              <div className='clinic-header-picture'>
                  <div className='picture-container'>
                        <img src={require('../images/main-picture.jfif')}/>
                  </div>
              </div>
            </header>
            <section className='popular-services'>
                <h2>{t('main-page.pop-service-title')}</h2>
                <div className='popular-service-list'>
                        {services.map(service => 
                        {
                        return(
                            <div className='popular-services-item' key={service._id}>
                                <img src={service.serviceImg} className='popular-service-img' alt='Service'/>
                                <div className='popular-service-content'>
                                    <div>
                                        <h3>{service.serviceName}</h3>
                                        <p>{service.description}</p>
                                    </div>
                                    <Link to={`/detail/service/${service._id}`} onClick={()=>localStorage.setItem('back-main','yes')} className='popular-service-btn'>{t('btns.readMore')}</Link>
                                </div>
                            </div>
                        )})}
                </div>
                <div className='show-services-btn'>
                  <Link to='/services' className='btn-services'>{t('btns.all-services')}</Link>
                </div>
            </section>
            <section className='popular-capelnisya'>
                <h2>{t('main-page.pop-capelnisya-title')}</h2>
                <div className='popular-service-list'>
                    {capelnisyas.map(capelnisya =>{
                        return(
                            <div className='popular-services-item' key={capelnisya._id}>
                                <img src={capelnisya.capelnisyaImg} className='service-3d-img'/>
                                <div className='popular-capelnisya-img'></div>
                                <div className='popular-service-content'>
                                    <div>
                                        <h3>{capelnisya.capelnisyaName}</h3>
                                        <p>{capelnisya.description}</p>
                                    </div>
                                    <Link to={`/detail/capelnisya/${capelnisya._id}`} onClick={()=>localStorage.setItem('back-main','yes')} className='popular-service-btn'>{t('btns.readMore')}</Link>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className='show-services-btn'>
                  <Link to='/capelnisya' className='btn-services'>{t('btns.all-capelnisyas')}</Link>
                </div>
            </section>
        </main>
        <Footer/>
    </div>
  )
}
