import React, { useCallback, useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { useTranslation } from 'react-i18next';
import Loader from '../components/Loader';
import { Link } from 'react-router-dom';

export default function Services() {
    const [numTurn, setNumTurn] = useState(()=>{
        return localStorage.getItem('lastNumTurnService') ? parseInt(localStorage.getItem('lastNumTurnService')) : 1;
    });
    const [services, setServices] = useState([])  
    const { t, i18n } = useTranslation();

    function setNumTurnClick(index){
        setNumTurn(index);
        localStorage.setItem('lastNumTurnService', index); // Сохраняем текущее numTurn
    }

    const fetchLinks = useCallback(() => {
        const serviceKeys = Object.keys(t('services', { returnObjects: true }));
        
        // Трансформация данных услуг
        const translatedServices = serviceKeys.map(key => ({
            _id: key,
            serviceName: t(`services.${key}.serviceName`),
            period: t(`services.${key}.period`),
            shortDesc:t(`services.${key}.short-desc`),
            price: t(`services.${key}.price`),
            serviceImg: t(`services.${key}.serviceImg`),
            category:t(`services.${key}.category`)
        }));
        
        setServices(translatedServices);
    }, [t]);

    console.log("Services: " , services);
    

    useEffect(() => {
        fetchLinks();
    }, [fetchLinks, i18n.language]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if(!services){
        return <Loader/>
    }

  return (
    <div>
        <Navbar/>
        <section className='services-container'>
                <div className='services-nav' id='services-nav'>
                    <button className={numTurn === 1 ? 'active' : ''} onClick={()=>setNumTurnClick(1)}>
                        {t('services-nav.nav1')}
                    </button>
                    <button className={numTurn === 2 ? 'active' : ''} onClick={()=>setNumTurnClick(2)}>
                        {t('services-nav.nav2')}
                    </button>
                    <button className={numTurn === 3 ? 'active' : ''} onClick={()=>setNumTurnClick(3)}>
                        {t('services-nav.nav3')}
                    </button>
                </div>
                {numTurn === 1 && (
                    <main className='services-grid'>
                        {services
                        .filter(service => service.category === "Жалпы қызметтер" || service.category === "Общие услуги")
                        .map(service => (
                            <Link to={`/detail/service/${service._id}`} className='services-grid-card' key={service._id}>
                                <div className='services-grid-img'>
                                    <img src={service.serviceImg} alt='services-grid-img' />
                                </div>
                                <div className='services-grid-content'>
                                    <h4>{service.serviceName}</h4>
                                    <p>{service.shortDesc}</p>
                                    <div className='price-box'>
                                        <p>{t('services.price')}</p>
                                        <h3>{service.price}</h3>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </main>
                )}
                {numTurn === 2 && (
                    <main className='services-grid'>
                        {services
                        .filter(service => service.category === "Check-up")
                        .map(service => (
                            <Link to={`/detail/service/${service._id}`} className='services-grid-card' key={service._id}>
                                <div className='services-grid-img'>
                                    <img src={service.serviceImg} alt='services-grid-img' />
                                </div>
                                <div className='services-grid-content'>
                                    <h4>{service.serviceName}</h4>
                                    {/\d/.test(service.shortDesc) ? (
                                        <p>{t('services.period-service')}: {service.period}</p>
                                    ) : (
                                        <p>{service.shortDesc}</p>
                                    )}
                                    <div className='price-box'>
                                        <p>{t('services.price')}</p>
                                        <h3>{service.price}</h3>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </main>
                )}
                {numTurn === 3 && (
                    <main className='services-grid'>
                        {services
                        .filter(service => service.category === "Бустер шоттар" || service.category === "Бустер шоты")
                        .map(service => (
                            <Link to={`/detail/service/${service._id}`} className='services-grid-card' key={service._id}>
                                <div className='services-grid-img'>
                                    <img src={service.serviceImg} alt='services-grid-img' />
                                </div>
                                <div className='services-grid-content'>
                                    <h4>{service.serviceName}</h4>
                                    <p>{service.shortDesc}</p>
                                    <div className='price-box'>
                                        <p>{t('services.price')}</p>
                                        <h3>{service.price}</h3>
                                    </div>
                                </div>
                            </Link>
                        ))}
                    </main>
                )}
        </section>
        <Footer/>
    </div>
  )
}
