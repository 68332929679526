import React, { useCallback, useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import { useTranslation } from 'react-i18next';
import Loader from '../components/Loader';
import { Link } from 'react-router-dom';

export default function Capelnisya() {
    const [numTurn, setNumTurn] = useState(()=>{
        return localStorage.getItem('lastNumTurn') ? parseInt(localStorage.getItem('lastNumTurn')) : 1;
    });
    const [capelnisyas, setCapelnisyas] = useState([])
    const [loading, setLoading] = useState(true);  // Добавлено состояние загрузки  
    const { t, i18n } = useTranslation();

    function setNumTurnClick(index){
        setNumTurn(index);
        localStorage.setItem('lastNumTurn', index); // Сохраняем текущее numTurn
    }

    const fetchLinks = useCallback(() => {
        const capelnisyaKeys = Object.keys(t('capelnisyas', { returnObjects: true }));
        
        // Трансформация данных услуг
        const translatedCapelnisyas = capelnisyaKeys.map(key => ({
            _id: key,
            capelnisyaName: t(`capelnisyas.${key}.capelnisyaName`),
            shortDesc: t(`capelnisyas.${key}.short-desc`),
            description: t(`capelnisyas.${key}.description`),
            price: t(`capelnisyas.${key}.price`),
            capelnisyaImg: t(`capelnisyas.${key}.capelnisyaImg`),
            category: t(`capelnisyas.${key}.category`)
        }));
        
        setCapelnisyas(translatedCapelnisyas);
        setLoading(false);  // Устанавливаем загрузку в false после получения данных
    }, [t]);

    useEffect(() => {
        fetchLinks();
    }, [fetchLinks, i18n.language]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    if(loading){
        return <Loader/>
    }

    return (
    <div>
        <Navbar/>
        <section className='capelnisya-container'>
                <div className='services-nav' id='services-nav'>
                    <button className={numTurn === 1 ? 'active' : ''} onClick={()=>setNumTurnClick(1)}>
                        {t('capelnisya-nav.nav1')}
                    </button>
                    <button className={numTurn === 2 ? 'active' : ''} onClick={()=>setNumTurnClick(2)}>
                        {t('capelnisya-nav.nav2')}
                    </button>
                    <button className={numTurn === 3 ? 'active' : ''} onClick={()=>setNumTurnClick(3)}>
                        {t('capelnisya-nav.nav3')}
                    </button>
                </div>
                {numTurn === 1 && (
                    <main className='capelnisya-grid'>
                        {capelnisyas
                        .filter(capelnisya => capelnisya.category === "Американдық" || capelnisya.category === "Американские")
                        .map(capelnisya=>(
                                <Link to={`/detail/capelnisya/${capelnisya._id}`} className='capelnisya-grid-card' key={capelnisya._id}>
                                    <img src={capelnisya.capelnisyaImg} className='capelnisya-3d-img'/>
                                    <div className='capelnisya-grid-img'></div>
                                    <div className='capelnisya-grid-content'>
                                        <h4>{capelnisya.capelnisyaName}</h4>
                                        <p>{capelnisya.shortDesc}</p>
                                        <div className='price-box'>
                                            <p>{t('services.price')}</p>
                                            <h3>{capelnisya.price}</h3>
                                        </div>
                                    </div>
                                </Link>
                            )
                        )}
                    </main>
                )}
                {numTurn === 2 && (
                    <main className='capelnisya-grid'>
                        {capelnisyas
                        .filter(capelnisya => capelnisya.category === "Кореялық" || capelnisya.category === "Корейские")
                        .map(capelnisya=>(
                                <Link to={`/detail/capelnisya/${capelnisya._id}`} className='capelnisya-grid-card' key={capelnisya._id}>
                                    <img src={capelnisya.capelnisyaImg} className='capelnisya-3d-img'/>
                                    <div className='capelnisya-grid-img'></div>
                                    <div className='capelnisya-grid-content'>
                                        <h4>{capelnisya.capelnisyaName}</h4>
                                        <p>{capelnisya.shortDesc}</p>
                                        <div className='price-box'>
                                            <p>{t('services.price')}</p>
                                            <h3>{capelnisya.price}</h3>
                                        </div>
                                    </div>
                                </Link>
                            )
                        )}
                    </main>
                )}
                {numTurn === 3 && (
                    <main className='capelnisya-grid'>
                        {capelnisyas
                        .filter(capelnisya => capelnisya.category === "Авторлық" || capelnisya.category === "Авторские")
                        .map(capelnisya=>(
                                <Link to={`/detail/capelnisya/${capelnisya._id}`} className='capelnisya-grid-card' key={capelnisya._id}>
                                    <img src={capelnisya.capelnisyaImg} className='capelnisya-3d-img'/>
                                    <div className='capelnisya-grid-img'></div>
                                    <div className='capelnisya-grid-content'>
                                        <h4>{capelnisya.capelnisyaName}</h4>
                                        <p>{capelnisya.shortDesc}</p>
                                        <div className='price-box'>
                                            <p>{t('services.price')}</p>
                                            <h3>{capelnisya.price}</h3>
                                        </div>
                                    </div>
                                </Link>
                            )
                        )}
                    </main>
                )}
        </section>
        <Footer/>
    </div>
  )
}
