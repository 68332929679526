import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import { API_URL } from '../utils/config';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function ReserveClinic() {
    const [formData, setFormData] =  useState({
        name:'',
        email:'',
        phone:''
      })
    const [loading, setLoading] = useState(false)
    const { t } = useTranslation()
    const navigate = useNavigate()
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value
        });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        try {
          const response = await fetch(`${API_URL}/api/data`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
          });
          setLoading(false)
          const data = await response.json();
          setFormData({
            name:'',
            email:'',
            phone:''
          })
          navigate('/confirmation')
        } catch (error) {
          setLoading(false)
          console.error('There was an error submitting the form!', error);
        }
      };
  return (
    <div>
        <Navbar/>
        <section className='reserve-clinic'>
            <div className='form-container'>
              <h4 className='reserve-title'>{t('reserve.reserve-form')}</h4>
              <form onSubmit={handleSubmit} className='form'>
                  <div className='form-item'>
                      <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder={t('reserve.reserve-name')} required/>
                  </div>
                  <div className='form-item'>
                      <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder={t('reserve.reserve-email')} required/>
                  </div>
                  <div className='form-item'>
                      <input type="tel" name="phone" value={formData.phone} onChange={handleChange} placeholder={t('reserve.reserve-phone')} required/>
                  </div>
                  <button type="submit" disabled={loading}>
                    {loading ? `${t('reserve.reserve-send')}` : `${t('btns.reserve')}`}
                  </button>
              </form>
            </div>
        </section>
    </div>
  )
}
