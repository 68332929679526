import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export default function Footer() {
   const {t} = useTranslation()
    
  return (
    <footer className='footer'>
             <div className='footer-header'>
                <div className='footer-logo footer-header-item'>
                    <img src={require('../images/nutrilife-logo.png')} alt="footer-logo"/>
                </div>
                <div className='footer-company-name footer-header-item'>
                    <p>© Nutrilife Clinic</p>
                </div>
                <div className='footer-icons footer-header-item'>
                    <Link to={'https://www.instagram.com/nutrilifeclinic.kz?igsh=a3NqYTB5bmRodzNh&utm_source=qr'} target='_blank' rel='noopener noreferrer'>
                        <img src={require('../images/icons/insta-icon.png')}  alt="footer-icon1"/>
                    </Link>
                    <Link to={'https://wa.me/7051784600'} target='_blank' rel='noopener noreferrer'>
                        <img src={require('../images/icons/whatsapp-icon.png')}  alt="footer-icon2"/>
                    </Link>
                    <Link to={'https://youtube.com/@nutrilifeclinickz?si=76SvlVY8zvA-mc3K'} target='_blank' rel='noopener noreferrer'>
                        <img src={require('../images/icons/youtube-icon.png')}  alt="footer-icon2"/>
                    </Link>
                    <Link to={'https://www.tiktok.com/@nutrilifeclinic.kz?_t=8odlKncAAlB&_r=1'} target='_blank' rel='noopener noreferrer'>
                        <img src={require('../images/icons/tiktok-icon.png')}  alt="footer-icon3"/>
                    </Link>
                </div>
             </div>
             <div className='footer-content'>
                <div className='footer-item'>
                    <Link to="/" onClick={(e) => {
                        if (window.location.pathname === '/') {
                            e.preventDefault(); // предотвратить обычный переход по ссылке
                            setTimeout(() => {
                                window.location.reload();
                            }, 500);
                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth'
                            });
                        }
                    }}>
                        {t('navbar.home')}
                    </Link>
                    <Link to="/about-us" onClick={(e) => {
                        if (window.location.pathname === '/about-us') {
                            e.preventDefault(); // предотвратить обычный переход по ссылке
                            setTimeout(() => {
                                window.location.reload();
                            }, 600);
                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth'
                            });
                        }
                    }}>
                        {t('navbar.about')}
                    </Link>
                    <Link to="/about-us" onClick={(e) => {
                        if (window.location.pathname === '/about-us') {
                            e.preventDefault(); // предотвратить обычный переход по ссылке
                            setTimeout(() => {
                                window.location.reload();
                            }, 500);
                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth'
                            });
                        }
                    }}
                    >
                        {t('btns.doctors')}
                    </Link>
                </div>
                <div className='footer-item'>
                    <Link to="/about-us" onClick={(e) => {
                        if (window.location.pathname === '/about-us') {
                            e.preventDefault(); // предотвратить обычный переход по ссылке
                            setTimeout(() => {
                                window.location.reload();
                            }, 500);
                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth'
                            });
                        }
                    }}>
                        {t('btns.contact')}
                    </Link>
                    <Link to="/services" onClick={(e) => {
                        if (window.location.pathname === '/services') {
                            e.preventDefault(); // предотвратить обычный переход по ссылке
                            localStorage.setItem('lastNumTurnService', 1);
                            setTimeout(() => {
                                window.location.reload();
                            }, 500);
                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth'
                            });
                        } else {
                            localStorage.setItem('lastNumTurnService', 1);
                        }
                    }}
                    >
                        {t('navbar.services')}
                    </Link>
                    <Link to="/capelnisya" onClick={(e) => {
                        if (window.location.pathname === '/capelnisya') {
                            e.preventDefault(); // предотвратить обычный переход по ссылке
                            localStorage.setItem('lastNumTurn', 1);
                            setTimeout(() => {
                                window.location.reload();
                            }, 500);
                            window.scrollTo({
                                top: 0,
                                behavior: 'smooth'
                            });
                        } else {
                            localStorage.setItem('lastNumTurn', 1);
                        }
                    }}
                    >
                        {t('navbar.capelnisya')}
                    </Link>
                </div>
             </div>             
        </footer>
  )
}
