import React from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

export default function Confirmation() {
  const { t } = useTranslation();
  return (
    <div>
        <Navbar/>
        <section className='confirmation'>
            <img src={require('../images/confirmation.png')} alt='confirmation-img'/>
            <h2>{t('confirmation.congrats')}</h2>
            <p>{t('confirmation.congrats-content')}</p>
            <Link to='/' className='confirmation-btn'>{t('confirmation.congrats-btn')} &gt;</Link>
        </section>
        <Footer/>
    </div>
  )
}
