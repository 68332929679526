import React, { useCallback, useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loader from '../components/Loader';

export default function DetailCapelnisya() {
    const { t, i18n } = useTranslation();
    const [capelnisya, setCapelnisya] = useState(null)
    const capelnisyaId= useParams().id
    const navigate = useNavigate()

    const getCapelnisya = useCallback(() => {
        const capelnisyaData = {
            capelnisyaName: t(`capelnisyas.${capelnisyaId}.capelnisyaName`),
            description: t(`capelnisyas.${capelnisyaId}.description`),
            course: t(`capelnisyas.${capelnisyaId}.course`),
            period: t(`capelnisyas.${capelnisyaId}.period`),
            quram: t(`capelnisyas.${capelnisyaId}.quram`),
            detailPrice: t(`capelnisyas.${capelnisyaId}.detail-price`),
            price: t(`capelnisyas.${capelnisyaId}.price`),
            capelnisyaImg: t(`capelnisyas.${capelnisyaId}.capelnisyaImg`),
            category: t(`capelnisyas.${capelnisyaId}.category`)
        };
        setCapelnisya(capelnisyaData);

    }, [capelnisyaId, t]);

    useEffect(() => {
        getCapelnisya();
    }, [getCapelnisya, i18n.language]);

    const handleBack = () => {
        const backMain = localStorage.getItem('back-main');
        if (backMain === 'yes') {
          navigate('/');
          localStorage.removeItem('back-main');
        }else{
          navigate('/capelnisya');
        }
      };

  if (!capelnisya || !capelnisya.capelnisyaImg) {
    return <Loader/>;
  }

  return (
    <div>
        <Navbar/>
        <div className='detail-capelnisya'>
            <button onClick={()=>handleBack()} className='btn-back'>&lt; {t('btns.back')}</button>
            <header className='detail-header'>
                <div className='detail-capelnisya-img'>
                    {capelnisya.capelnisyaImg && (
                        <img src={capelnisya.capelnisyaImg} alt='detail-capelnisya-img'/>
                    )}
                </div>
                <div className='detail-content'>
                    <div className='detail-content-title'>
                        <h2>{capelnisya.capelnisyaName}</h2>  
                        <p>Капельница &gt; {capelnisya.category}</p>
                    </div>
                    <div className='detail-capelnisya-price'>
                        <h4>{t('services.price')}:</h4>
                        <h2>{capelnisya.price}</h2>
                    </div>
                    <div className='detail-capelnisya-quickAccess'>
                        <h4>{t('services.qyzmetter-quickAccess-title1')}</h4>
                        <Link to='/reserve-clinic' className='quick-access-btn'>{t('btns.reserve')} &gt;</Link>
                    </div>
                </div>
            </header>
            <section className='detail-description'>
                {(capelnisya.category === "Кореялық" || capelnisya.category === "Корейские" || capelnisya.capelnisyaName === "NAD+") &&(
                    <>
                        <div className='detail-description-content'>
                            <h2>Курс</h2>
                            <p>{capelnisya.course}</p>
                        </div>
                        <div className='detail-description-content'>
                            <h2>{t('capelnisyas.aralyq')}</h2>
                            <p>{capelnisya.period}</p>
                        </div>
                    </>
                )}
                {(capelnisya.category === "Авторлық" || capelnisya.category === "Авторские") &&(
                        <div className='detail-description-content'>
                            <h2>Курс</h2>
                            <p>{capelnisya.course}</p>
                        </div>
                )}
                {(capelnisya.category === "Американдық" || capelnisya.category === "Американские") &&(
                        <div className='detail-description-content'>
                            <h2>{t('capelnisyas.quram-title')}</h2>
                            {capelnisya.quram.split('\n').map(quram => (
                                    <p>- {quram}</p>
                            ))}
                        </div>
                )}
                {(capelnisya.capelnisyaName === "Жоғары дозалы С дәрумені" || capelnisya.capelnisyaName === "ВИТАМИН С-Высокодозный" || capelnisya.capelnisyaName === "Лаеннек") && (
                    <div className='detail-description-content'>
                        <h2>{t('capelnisyas.detail-price-title')}</h2>
                        {capelnisya.detailPrice.split('\n').map(price => (
                                    <p>- {price}</p>
                        ))}
                    </div>
                )}
                <div className='detail-description-content'>
                    <h2>{t('capelnisyas.capelnisya-desc')}</h2>
                    {capelnisya.description.split('\n').map(paragraph => (
                                <p>- {paragraph}</p>
                    ))}
                </div>
            </section>
            <section className='detail-bottom-quickAccess'>
                <h3>{t('services.qyzmetter-quickAccess-title2')}</h3>
                <Link to='/reserve-clinic' className='quick-access-btn'>{t('btns.reserve')} &gt;</Link>
            </section>
        </div>
    </div>
  )
}
