import React, { useCallback, useEffect, useState } from 'react'
import Navbar from '../components/Navbar'
import { Link, useNavigate, useParams } from 'react-router-dom';
import Loader from '../components/Loader';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line import/no-anonymous-default-export
export default function () {
    const { t, i18n } = useTranslation();
    const [service, setService] = useState(null)
    const serviceId= useParams().id
    const navigate = useNavigate();

    const getService = useCallback(() => {
        const serviceData = {
            serviceName: t(`services.${serviceId}.serviceName`),
            fullDesc: t(`services.${serviceId}.full-desc`),
            shortDesc: t(`services.${serviceId}.short-desc`),
            description: t(`services.${serviceId}.description`),
            period:t(`services.${serviceId}.period`),
            price: t(`services.${serviceId}.price`),
            serviceImg: t(`services.${serviceId}.serviceImg`),
            method: t(`services.${serviceId}.method`),
            category:t(`services.${serviceId}.category`)
        };
        setService(serviceData);

    }, [serviceId, t]);

    useEffect(() => {
        getService();
    }, [getService, i18n.language]);

  if (!service || !service.serviceImg) {
    return <Loader/>;
  }

  const handleBack = () => {
    const backMain = localStorage.getItem('back-main');
    if (backMain === 'yes') {
      navigate('/');
      localStorage.removeItem('back-main');
    }else{
      navigate('/services');
    }
  };

  console.log("Service: ", service);
  

  return (
    <div>
        <Navbar/>
        <div className='detail-page'>
            <button onClick={()=> handleBack()} className='btn-back'>&lt; {t('btns.back')}</button>
            <header className='detail-header'>
                <div className='detail-img'>
                    {service.serviceImg && (
                        <img src={service.serviceImg} alt='detail-service-img'/>
                    )}
                </div>
                <div className='detail-content'>
                    <div className='detail-content-title'>
                        <h2>{service.serviceName}</h2>  
                        <p>{t('services.qyzmetter')} &gt; {service.category}</p>
                    </div>
                    <div className='detail-service-price'>
                        <h4>{t('services.price')}:</h4>
                        <h2>{service.price}</h2>
                    </div>
                    <div className='detail-service-quickAccess'>
                        <h4>{t('services.qyzmetter-quickAccess-title1')}</h4>
                        <Link to='/reserve-clinic' className='quick-access-btn'>{t('btns.reserve')} &gt;</Link>
                    </div>
                </div>
            </header>
            <section className='detail-description'>
                {(service.category === "Жалпы қызметтер" || service.category === "Общие услуги") &&(
                    <div className='detail-description-content'>
                        <h2>{t('services.about-service')}</h2>
                        <p>{service.fullDesc}</p>
                    </div>
                )}
                {service.category === "Check-up" &&(
                    <>
                        {/\d/.test(service.shortDesc) ? (
                            <div className='detail-description-content'>
                                <h2>{t('services.period-service')}</h2>
                                <p>{service.period}</p>
                            </div>
                        ) : null}
                        <div className='detail-description-content'>
                            <h2>{t('services.perechen')}</h2>
                            {service.description.split('\n').map(paragraph => (
                                <p>- {paragraph}</p>
                            ))}
                        </div>
                    </>
                )}
                {(service.category === "Бустер шоттар" || service.category === "Бустер шоты") &&(
                    <>
                        <div className='detail-description-content'>
                            <h2>{t('services.method-title')}</h2>
                            <p>{service.method}</p>
                        </div>
                        <div className='detail-description-content'>
                            <h2>{t('services.busterShot-title')}</h2>
                            <p>{service.fullDesc}</p>
                        </div>
                    </>
                )}
            </section>
            <section className='detail-bottom-quickAccess'>
                <h3>{t('services.qyzmetter-quickAccess-title2')}</h3>
                <Link to='/reserve-clinic' className='quick-access-btn'>{t('btns.reserve')} &gt;</Link>
            </section>
        </div>
    </div> 
  )
}
