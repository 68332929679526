import React from 'react'
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom'
import MainPage from './pages/MainPage';
import ReserveClinic from './pages/ReserveClinic';
import AboutUs from './pages/AboutUs';
import Services from './pages/Services';
import Capelnisya from './pages/Capelnisya';
import DetailPage from './pages/DetailPage';
import Confirmation from './components/Confirmation';
import Loader from './components/Loader';
import DetailCapelnisya from './pages/DetailCapelnisya';

function App() {


  return (
    <Router>
        <Routes>
            <Route exact path='/' element={<MainPage/>}></Route>
            <Route exact path='/reserve-clinic' element={<ReserveClinic/>}></Route>
            <Route exact path='/about-us' element={<AboutUs/>}></Route>
            <Route exact path='/services' element={<Services/>}></Route>
            <Route exact path='/capelnisya' element={<Capelnisya/>}></Route>
            <Route exact path='/detail/service/:id' element={<DetailPage/>}></Route>
            <Route exact path='/detail/capelnisya/:id' element={<DetailCapelnisya/>}></Route>
            <Route exact path='/confirmation' element={<Confirmation/>}></Route>
            <Route exact path='/loader' element={<Loader/>}></Route>
        </Routes>
    </Router>
  );
}

export default App;
