import React from 'react';
import '../style/Loader.scss';
import Navbar from './Navbar';

export default function Loader() {
  return (
    <>
        <Navbar/>
        <div class="spinner">
            <span class="ball-1"></span>
            <span class="ball-2"></span>
            <span class="ball-3"></span>
            <span class="ball-4"></span>
            <span class="ball-5"></span>
            <span class="ball-6"></span>
            <span class="ball-7"></span>
            <span class="ball-8"></span>
        </div>
    </>
  );
}
